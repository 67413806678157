import { graphql } from 'gatsby';
import React from 'react';
import NewLayout from '../components/new_layout';
import Seo from '../components/seo';
import MainSection50 from '../components/Layout/main-section-50';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import MainSection100 from '../components/Layout/main-section-100';
import ImageGroup from '../components/v2024/image/image-group';
import ReadyToTalk from '../components/v2023/ReadyToTalk/ready-to-talk';

const ComplianceHub = ({ data, pageContext }) => {
	const page = data.page;
    const lang = pageContext.langKey
	return (
		<NewLayout pageContext={pageContext}>
			<Seo
				translates={pageContext.translates}
				title={page.seo.title}
				description={page.seo.description}
				image={page.seo.image_2}
			/>

            <div className="main">
                {/* Hero */}
                <MainSection50
                    sectionType="color"
                    sectionColor="purple-dark"
                    sectionSize="lg"
                    childrenLeft={
                        <>
                            <h1 className="merriweather">{page.hero_title}</h1>
                            <div className="container__text">
                                <ReactMarkdown children={page.hero_description} rehypePlugins={[rehypeRaw]} />
                            </div>
                        </>
                    }
                    childrenRight={
                        <GatsbyImage image={getImage(page.hero_image.localFile)} alt={page.hero_image.alternativeText} />
                    }
                />

                {/* Module section */}
                <MainSection50 
                    invert
                    childrenLeft={
                        <>
                        <h2>{page.module_title}</h2>
                        <div className="container__text">
                            <ReactMarkdown children={page.module_description} rehypePlugins={[rehypeRaw]} />
                        </div>
                    </>
                    } 
                    childrenRight={
                        <GatsbyImage image={getImage(page.module_image.localFile)} alt={page.module_image.alternativeText} />
                    } />

                {/* For you */}
                <MainSection50
                    childrenLeft={
                        <>
                            <h2>{page.for_you_title}</h2>
                            <div className="container__text">
                                <ReactMarkdown children={page.for_you_description} rehypePlugins={[rehypeRaw]} />
                            </div>
                        </>
                    }
                    childrenRight={
                        <GatsbyImage image={getImage(page.for_you_image.localFile)} alt={page.for_you_image.alternativeText} />
                    }
                />

                {/* Items */}
                <MainSection100>
                    <div className='grid-lg-4'>
                        {page.items.map(item => <ImageGroup basicInfoWithImage={item} key={item.id} />)}
                    </div>
                </MainSection100>

                {/* Ready to Talk */}
                <ReadyToTalk lang={lang} />
            
            </div>

		</NewLayout>
	);
};

export default ComplianceHub;

export const complianceQuery = graphql`
    query ($langKey: String) {
		page: strapi2024ComplianceHub (locale: { eq: $langKey }) {
			seo {
				title
				meta_description
				image_2 {
					localFile {
						publicURL
					}
				}
			}
			hero_title
			hero_description
			hero_image {
				name
				alternativeText
				localFile {
					childImageSharp {
						gatsbyImageData(width: 656, placeholder: BLURRED, formats: [WEBP])
					}
				}
			}
            module_title
            module_description
            module_image {
                name
                alternativeText
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 912
                            placeholder: BLURRED
                            formats: [WEBP]
                        )
                    }
                }
            }
            for_you_title
            for_you_description
            for_you_image {
                name
                alternativeText
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            width: 912
                            placeholder: BLURRED
                            formats: [WEBP]
                        )
                    }
                }
            }
            items {
                id
                title
                description
                image {
                    name
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                width: 314
                                placeholder: BLURRED
                                formats: [WEBP]
                            )
                        }
                    }
                }
            }
		}
	}
`;
